import { useQuery, useSubscription } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CSVDownloader } from 'react-papaparse';
import { TabTitle } from '../../components/common';
import './candidates.css';
import { formatSalary } from '../../utils/formatter';

const ModalContext = React.createContext();

const ALL_PLACEMENT_REPORT = gql`
  subscription getCompanyRequest($start_date: date, $end_date: date) {
    company_request( where: { status: {_eq: "hired"}, hired_date: { _gte: $start_date, _lte: $end_date }}, order_by: { hired_date: desc }) {
    hired_date
        hired_salary
        start_date
        candidate {
          name
          email
          phone
        }
        candidate {
          recruiter {
            name
            email
          }
        }
      company {
        name
        email
      }
    }
}
`;
const SETTINGS = gql`
  query getSettings {
    settings {
      placement_fee_percentage
      guarantee_period
      recruiter_share
      cherrypicker_share
    }
  }
`;

export default function PlacementReport({ isAdmin, isRecruiter, isCompany, isAgency, inProcess, isDashboard }) {
    const [modal, setModal] = useState({ data: null, open: false });
    const [activityData, setActivityData] = useState();

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(new Date());
    const { data: feeConfigSettingsData } = useQuery(SETTINGS);
    const { cherrypicker_share, placement_fee_percentage, guarantee_period, recruiter_share } = feeConfigSettingsData?.settings?.[0] || {};

    const { data: recruiterActivityResponse, loading } = useSubscription(ALL_PLACEMENT_REPORT, {
        variables: {
            start_date: startDate != null ? moment(startDate).format('YYYY-MM-DD') : null,
            end_date: moment(endDate).format('YYYY-MM-DD'),
        },
        fetchPolicy: 'network-only',
    });

    // const { data: recruiterActivityResponse, loading } = useSubscription(ALL_PLACEMENT_REPORT);
    const guaranteePeriodDays = guarantee_period
    useEffect(() => {
        if (recruiterActivityResponse) {
            setActivityData(recruiterActivityResponse.company_request);
        }
    }, [recruiterActivityResponse]);

    function _mapActivityData(activity) {
        const guaranteeDate = moment(activity.hired_date).add(Number(guaranteePeriodDays) || 0, 'days').format('MM-DD-YYYY');
        return {
            'Transaction Date': activity?.hired_date ? moment(activity.hired_date).format('MM/DD/YYYY') : 'N/A',
            'Start Date': activity?.start_date ? moment(activity.start_date).format('MM/DD/YYYY') : 'N/A',
            'Hired Salary': activity.hired_salary ? formatSalary({ salary: `$${activity.hired_salary}` }) : 'N/A',
            'Hiring Company Name': activity.company ? activity.company.name : 'N/A',
            'Recruiter Name': activity.candidate.recruiter ? activity.candidate.recruiter.name : 'N/A',
            'Candidates': activity.candidate ? activity.candidate.name : 'N/A',
            'Guaranteed Date': guaranteeDate ? guaranteeDate : 'N/A',
        };
    }

    const csvData = activityData ? activityData.map(_mapActivityData) : [];

    return (
        <div className="w-full flex-1 candidates">
            <ModalContext.Provider value={[modal, setModal]}>
                <div className="relative flex items-center" style={{ marginTop: 30, marginBottom: 30, zIndex: 200 }}>
                    <TabTitle showBackButton={true}>{'Placement Report'}</TabTitle>
                    {activityData && activityData.length > 0 && (
                        <CSVDownloader
                            data={csvData}
                            type="anchor"
                            filename={'recruiter_activity'}
                            className={`bg-red text-white font-medium rounded flex items-center justify-center`}
                            style={{ fontSize: 14, height: 40, width: 190, marginRight: 10 }}
                        >
                            <span style={{ fontSize: 22, marginRight: 8 }}></span> Download CSV
                        </CSVDownloader>
                    )}
                </div>
                <div className="h-full flex flex-col" style={{ width: '100%', height: 60, display: '-webkit-inline-box' }}>
                    <div style={{ marginRight: 20 }}>
                        <span>Start Date: </span>
                        <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} maxDate={moment(new Date()).toDate()} />
                    </div>
                    <div>
                        <span>End Date: </span>
                        <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} minDate={startDate} maxDate={moment(new Date()).toDate()} />
                    </div>
                </div>
                <div className={`flex-1 bg-white rounded shadow candidates-container candidate-blank-dash`} style={{ overflowX: 'auto' }}>
                    <table style={{ width: '100%' }}>
                        <thead style={{ fontSize: 14, fontWeight: 'bold' }}>
                            <tr style={{ position: 'sticky', top: 0, background: 'white' }}>
                                <th style={{ paddingLeft: '5px', textAlign: 'center' }}>Transaction Date</th>
                                <th style={{ textAlign: 'left', paddingLeft: '15px' }}>
                                    <div className="flex flex-col">
                                        <div className="lg:p-0">Candidate Name</div>
                                        {!isCompany ? <><div className="lg:p-0 font-medium">
                                            <div className="text-darkblue mr-sm" style={{ whiteSpace: 'nowrap' }}>
                                                Candidate Email
                                            </div>
                                        </div>
                                            <div className="lg:p-0 font-medium">
                                                <div className="mr-sm text-darkgray">Candidate Phone</div>
                                            </div></> : null}
                                    </div>
                                </th>
                                <th style={{ textAlign: 'left' }}>
                                    <div className="flex flex-col">
                                        {!isCompany ? <div className="lg:p-0">Recruiter Name</div> : null}
                                        {isAdmin ? <div className="lg:p-0 font-medium">
                                            <div className="text-darkblue mr-sm" style={{ whiteSpace: 'nowrap' }}>
                                                Recruiter Email
                                            </div>
                                        </div> : null}
                                    </div>
                                </th>
                                <th style={{ textAlign: 'left' }}>
                                    {!isCompany ? <div className="lg:p-0">Subscriber Company Name </div> : null}
                                    {isAdmin ? <div className="lg:p-0 font-medium">
                                        <div className="text-darkblue mr-sm" style={{ whiteSpace: 'nowrap' }}>
                                            Subscriber Email
                                        </div>
                                    </div> : null}
                                </th>
                                <th style={{ textAlign: 'left' }}>
                                    <div className="lg:p-0">Start Date</div>
                                    <div className="lg:p-0 font-medium">
                                        <div className="text-darkblue mr-sm" style={{ whiteSpace: 'nowrap' }}>
                                            Guarantee Date
                                        </div>
                                    </div>
                                </th>
                                <th style={{ textAlign: 'left' }}>
                                    <div className="lg:p-0">Salary Hired</div>
                                </th>
                                {
                                    isCompany ? <th style={{ textAlign: 'left' }}>
                                        <div className="lg:p-0">Placement Fee</div>
                                    </th> : null
                                }
                                <th style={{ textAlign: 'left', paddingLeft: '15px' }}>
                                    <div className="flex flex-col">
                                        <div className="lg:p-0">{isCompany ? 'Placement Fee' : 'Total Fee'}</div>
                                        {!isCompany ? <><div className="lg:p-0 font-medium">
                                            <div className="text-darkblue mr-sm" style={{ whiteSpace: 'nowrap' }}>
                                                Cherrypicker Share
                                            </div>
                                        </div>
                                            <div className="lg:p-0 font-medium">
                                                <div className="mr-sm text-darkgray">Recruiter Share</div>
                                            </div></> : null}
                                    </div>
                                </th>
                                {/* <th style={{ textAlign: 'center' }}>Guaranteed Date</th> */}
                            </tr>
                        </thead>

                        <tbody>
                            {(activityData || []).map((activity, i) => {
                                const {
                                    start_date = 'N/A',
                                    hired_salary = 'N/A',
                                    candidate: {
                                        name: candidateName = 'N/A',
                                        email: candidateEmail = 'N/A',
                                        phone: candidatePhone = 'N/A',
                                        recruiter: {
                                            name: recruiterName = 'N/A',
                                            email: recruiterEmail = 'N/A',
                                        } = {}
                                    } = {},
                                    company: {
                                        name: companyName = 'N/A',
                                        email: companyEmail = 'N/A'
                                    } = {}
                                } = activity || {};
                                const guaranteeDate = moment(new Date(activity.hired_date)).add(Number(guaranteePeriodDays) || 0, 'days').format('MM/DD/YYYY');
                                const startDay = moment(new Date(start_date)).format('MM/DD/YYYY') || 'N/A'

                                const fee = placement_fee_percentage; // Calculate the 15% fee
                                const cherrypickerShare = Math.ceil(Number(fee) * (cherrypicker_share / 100));
                                const agencyShare = Math.ceil(Number(fee) * (recruiter_share / 100));

                                return (
                                    <tr key={`activity${i}`}>
                                        <td className='text-center text-14'>
                                            <div className="lg:p-0" style={{ fontWeight: 'bold' }}>
                                                {activity?.hired_date ? moment(new Date(activity.hired_date)).format('MM/DD/YYYY') : 'N/A'}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="flex flex-col text-14">
                                                <div className="lg:p-0" style={{ fontWeight: 'bold' }}>
                                                    {candidateName}
                                                </div>
                                                {!isCompany ? <><div className="lg:p-0 font-medium">
                                                    <div className="text-darkblue mr-sm">{candidateEmail}</div>
                                                </div>
                                                    <div className="lg:p-0 font-medium">
                                                        <div className="mr-sm text-darkgray">{candidatePhone}</div>
                                                    </div></> : null}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="flex flex-col text-14">
                                                {!isCompany ? <div className="lg:p-0" style={{ fontWeight: 'bold' }}>
                                                    {recruiterName}
                                                </div> : null}
                                                {isAdmin ? <div className="lg:p-0 font-medium">
                                                    <div className="text-darkblue mr-sm">{recruiterEmail}</div>
                                                </div> : null}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="flex flex-col text-14">
                                                {!isCompany ? <div className="lg:p-0" style={{ fontWeight: 'bold' }}>
                                                    {companyName}
                                                </div> : null}
                                                {isAdmin ? <div className="lg:p-0 font-medium">
                                                    <div className="text-darkblue mr-sm">{companyEmail}</div>
                                                </div> : null}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="flex flex-col text-14">
                                                <div className="lg:p-0" style={{ fontWeight: 'bold' }}>
                                                    {startDay}
                                                </div>
                                                <div className="lg:p-0 font-medium">
                                                    <div className="text-darkblue mr-sm">{guaranteeDate}</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="flex flex-col text-14">
                                                <div className="lg:p-0" style={{ fontWeight: 'bold' }}>
                                                    {formatSalary({ salary: `$${hired_salary}` })}
                                                </div>
                                            </div>
                                        </td>
                                        {
                                            isCompany ? <td>
                                                <div className="flex flex-col text-14 text-center pr-16">
                                                    <div className="lg:p-0" style={{ fontWeight: 'bold' }}>
                                                        ${placement_fee_percentage}
                                                    </div>
                                                </div>
                                            </td> : null
                                        }
                                        <td>
                                            <div className="flex flex-col text-14">
                                                <div className="lg:p-0" style={{ fontWeight: 'bold' }}>
                                                    {formatSalary({ salary: `$${fee}` })}
                                                </div>
                                                {!isCompany ? <><div className="lg:p-0 font-medium">
                                                    <div className="text-darkblue mr-sm">   {formatSalary({ salary: `$${cherrypickerShare}` })}</div>
                                                </div>
                                                    <div className="lg:p-0 font-medium">
                                                        <div className="mr-sm text-darkgray">{formatSalary({ salary: `$${agencyShare}` })}</div>
                                                    </div> </> : null}
                                            </div>
                                        </td>
                                        {/* <td>
                                            <div className="font-medium text-darkgray flex items-center" style={{ fontSize: 14, justifyContent: 'center' }}>
                                                {guaranteeDate ? guaranteeDate : 'N/A'}
                                            </div>
                                        </td> */}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>

                    {loading && (
                        <div className={`flex justify-center items-center flex-1 candidate-blank${isDashboard ? '-dash' : ''}`} style={{ width: '100%' }}>
                            <div className="bg-lightgray text-darkgray py-sm px-md" style={{ borderRadius: 43 }}>
                                Loading
                            </div>
                        </div>
                    )}
                </div>
            </ModalContext.Provider>
        </div>
    );
}
