import React, { forwardRef, useState, useImperativeHandle, useRef } from 'react';
import CaretDownGray from '../../images/caret-down-gray.png';
import { Results } from '../../views/candidates/company-candidates';
import { subscriber as subscriberTableHeaders, admin as adminTableHeaders, recuriter as recuriterTable, agency as agencyTable } from '../common/Table/headers';
import _ from 'lodash';
import '../common/Table/Table.css';
import { USER_ROLES } from '../../utils/constants';
import { toast, ToastContainer } from 'material-react-toastify';

/**
 * 
 * @param {Object} props Table Props
 * @param {String} props.ROLE User Role
 * @param {Array<Candidate>} props.selectedCandidates Selected Candidates
 * @param {Function} props.onCandidateSelect Updated selected candidate state
 * @param {String} props.onChange Update candidate rows
 * @param {Function} props.onSortChange User Role
 * @returns {React.FunctionComponent}
 */
const CandidatesTable = forwardRef((props, ref) => {

  // Refs
  const resultRef = useRef();
  const [isAllSelected, setIsAllSelected] = useState(false);
  useImperativeHandle(ref, () => ({
    selectedCandidates: resultRef?.current?.selectedCandidates || [],
  }));

  const headers = {
    [USER_ROLES.ADMIN]: adminTableHeaders,
    [USER_ROLES.RECRUITER]: recuriterTable,
    [USER_ROLES.SUBSCRIBER]: subscriberTableHeaders,
    [USER_ROLES.AGENCY]: agencyTable,
  }

  const toggleSelectAllCandidate = () => {
    let candIds = [];
    if (props.selectedCandidates.length > 0) {
      candIds = [];
      setIsAllSelected(false);
      // toast.success(`${candIds.length} Records Selected`)
    } else {
      props.candidates?.map((candidate) => candIds.push(candidate?.candidate?.id));
      setIsAllSelected(true);
      toast.success(`${candIds.length} records are selected out of ${props?.candidateCount} total records`)
    }
    props.onCandidateSelect(candIds);
  };

  // Utility function to filter headers based on excluded keys
  const filterHeaders = (headers, keysToExclude) =>
    headers?.filter(({ key }) => !keysToExclude.includes(key)) || [];

  // Pre-filtered headers for subscriber role
  const subscriberHeaders = headers[USER_ROLES.SUBSCRIBER] || [];
  const filteredHeaders = {
    default: filterHeaders(subscriberHeaders, ['company_requests_aggregate', 'status', 'request_sent_on']),
    savedCandidates: filterHeaders(subscriberHeaders, ['favorites', 'action', 'request_sent_on']),
    requestedCandidates: filterHeaders(subscriberHeaders, ['company_requests_aggregate', 'status']),
    isInterestedCandidates: filterHeaders(subscriberHeaders, ['company_requests_aggregate', 'request_sent_on', 'status']),
  };

  // Determine tableHeaders based on conditions
  const tableHeaders = props.ROLE 
    ? headers[props.ROLE] :
    props.isInterestedCandidates ?
      filteredHeaders.isInterestedCandidates
    : props.isRequestedCandidates 
      ? filteredHeaders.requestedCandidates 
      : props.isSavedCandidates 
        ? filteredHeaders.savedCandidates 
        : filteredHeaders.default;
  
  const handleTableHeaderSortClick = ({tableRow}) => {
    if(tableRow?.key === 'company_requests_aggregate'){
      tableRow.canSort && props.onSortChange(tableRow.key, props.sort[tableRow.key]?.max?.unlocked_date);
    } else {
      tableRow.canSort && props.onSortChange(tableRow.key, props.sort[tableRow.key])
    }
  }

  return (
    <div className="CandidatesTable-js bg-white overflow-auto">
      <table className="CandidatesTable_table " style={{ marginBottom: 0 }}>
        <thead className="CandidatesTable_header font-medium text-sm border-b-2">
          <tr>
            {
              _.cloneDeep(tableHeaders || [])?.map((tableRow, index) => (
                <th style={tableRow?.selectAll && tableRow?.customDivStyles} key={index} className={`${tableRow.customClasses} table-header-td`}>
                  <div className={`table-header-data ${tableRow?.subClasses || ''} ${tableRow.canSort ? 'sortable' : ''}`} style={tableRow.customDivStyles} onClick={() => handleTableHeaderSortClick({tableRow})}>
                    {/* Label */}
                    <span>
                      {!tableRow?.selectAll ? props.isSavedCandidates && tableRow.label === 'Action' ? 'Unlocked Status' : tableRow.label : <td style={tableRow.customDivStyles}>
                        <div onClick={toggleSelectAllCandidate} className={`${isAllSelected && props.selectedCandidates.length > 0 ? 'bg-green' : ''} border border-green cursor-pointer`} style={{ width: 14, height: 14, margin: 'auto' }} />
                      </td>}
                    </span>
                    <span>
                      {/* Sort */}
                      {
                        tableRow.canSort && props.sort[tableRow.key] ? (
                          <div className="transform -translate-x-2">
                            <img
                              src={CaretDownGray}
                              style={{
                                height: 5,
                                width: 7,
                                margin: 0,
                                transform: `rotate(${props.sort[tableRow.key] === 'desc' ? '0' : '180'}deg) `
                              }} />
                          </div>
                        ) : null
                      }
                    </span>
                  </div>
                </th>
              ))
            }
          </tr>
        </thead>
        <tbody>
          <Results
            candidates={props.candidates}
            selectedCandidates={props.selectedCandidates}
            ref={resultRef}
            filter={props.filter}
            ROLE={props.ROLE}
            showMessage={props.showMessage}
            colSpan={props.colSpan}
            isRequestedCandidates={props.isRequestedCandidates}
            isInterestedCandidates={props.isInterestedCandidates}
            isFavoriteCandidates={props.isFavoriteCandidates}
            isSavedCandidates={props.isSavedCandidates}
            isLoading={props.isLoading}
            filterBySearch={props.filterBySearch}
            onCandidateSelect={props.onCandidateSelect}
            onChange={props.onChange}
            handleFavStatusChange={props.handleFavStatusChange}
            handleCandidateStatusChange={props.handleCandidateStatusChange}
            excludeApprovedCandidate={props.excludeApprovedCandidate}
            handleCandidateCancelStatus={props.handleCandidateCancelStatus}
          />
        </tbody>
      </table>
      <ToastContainer style={{ width: 'fit-content' }} theme="dark" position="bottom-center" autoClose={3000} hideProgressBar newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </div>
  );
})

export default CandidatesTable;
