export const subscriber = [
  {
    key: 'icon',
    label: ' ',
    customClasses: 'pl-4',
    customDivStyles: {},
  },
  {
    key: 'name',
    label: 'Candidate Details',
    canSort: false,
    customClasses: 'text-left',
    customDivStyles: { display: 'flex', justifyContent: 'flex-start' },
  },
  {
    key: 'experience',
    label: 'Experience',
    canSort: true,
    customClasses: 'text-center',
    customDivStyles: {},
  },
  {
    key: 'salaryMin',
    label: 'Salary Required',
    canSort: true,
    customClasses: 'text-center',
    customDivStyles: {},
  },
  {
    key: 'location.city',
    label: 'Location',
    canSort: false,
    subClasses: 'pl-27',
    customClasses: 'text-center location-paddingX-0',
    customDivStyles: {
      display: 'flex',
      justifyContent: 'flex-start'
    },
  },
  {
    key: 'favorites',
    label: 'Favorites',
    canSort: false,
    customClasses: 'text-center',
    customDivStyles: {},
  },
  /* TEMP_HIDE
  {
    key: 'request_sent_on',
    label: 'Request Sent On',
    canSort: false,
    customClasses: 'text-center',
    customDivStyles: {},
  },

  {
    key: 'interested_on',
    label: 'Interested On',
    canSort: false,
    customClasses: 'text-center',
    customDivStyles: {},
  },
  */
  // {
  //   key: 'chat',
  //   label: 'Chat',
  //   canSort: false,
  //   customClasses: 'text-center',
  //   customDivStyles: {},
  // },
  {
    key: 'action',
    label: 'Action',
    canSort: false,
    customClasses: 'text-center',
    subClasses: 'pl-40',
    customDivStyles: {},
  },
  {
    key: 'status',
    label: 'Hired Status',
    canSort: false,
    customClasses: 'text-center',
    subClasses: 'hired_status',
    customDivStyles: {},
  },
  {
    key: 'company_requests_aggregate',
    label: 'Unlocked Date',
    canSort: true,
    customClasses: 'text-center',
    subClasses: 'pl-25',
    customDivStyles: {},
  },
];

export const admin = [
  {
    key: 'action',
    label: '',
    selectAll: true,
    canSort: false,
    customClasses: 'text-center',
    customDivStyles: { paddingRight: '4px' },
  },
  {
    key: 'icon',
    label: '',
    customClasses: 'pl-4',
    customDivStyles: {},
  },
  {
    key: 'name',
    label: 'Candidate Details',
    canSort: true,
    customClasses: 'text-left',
    customDivStyles: { display: 'flex', justifyContent: 'flex-start', marginLeft: '-0.9rem' },
  },
  {
    key: 'name',
    label: 'Recruiter Name',
    canSort: true,
    customClasses: 'text-center',
    customDivStyles: {
      paddingLeft: '15px'
    },
  },
  {
    key: 'experience',
    label: 'Experience',
    canSort: true,
    customClasses: 'text-center pl-0',
    subClasses: 'pl-0',
    customDivStyles: {},
  },
  {
    key: 'salaryMin',
    label: 'Salary',
    canSort: true,
    customClasses: 'text-center',
    subClasses: 'pl-20',
    customDivStyles: {
      paddingLeft: '5px'
    },
  },
  {
    key: 'location.city',
    label: 'Location',
    canSort: false,
    customClasses: 'text-left location-paddingX-0',
    customDivStyles: {
      display: 'flex',
      justifyContent: 'flex-start'
    },
  },
  {
    key: 'invitation',
    label: 'Invitation',
    canSort: false,
    customClasses: 'text-center',
    subClasses: '',
    customDivStyles: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingRight: '65px'
    },
  },
  /*

  {
    key: 'notify',
    label: '',
    canSort: false,
    customClasses: 'text-center',
    customDivStyles: {},
  },
  {
    key: 'action',
    label: 'Select/DeSelect',
    canSort: false,
    customClasses: 'text-center',
    customDivStyles: {},
  },
  */
];

export const recuriter = [
  {
    key: 'action',
    label: '',
    selectAll: true,
    canSort: false,
    customClasses: 'text-center',
    customDivStyles: { paddingRight: '0px', paddingLeft: '7px' },
  },
  {
    key: 'icon',
    label: '',
    customClasses: 'pl-4',
    customDivStyles: {},
  },
  {
    key: 'name',
    label: 'Candidate Details',
    canSort: true,
    customClasses: 'text-left',
    customDivStyles: { display: 'flex', justifyContent: 'flex-start', marginLeft: '-0.9rem' },
  },
  {
    key: 'experience',
    label: 'Experience',
    canSort: true,
    customClasses: 'text-center pl-0',
    customDivStyles: {},
  },
  {
    key: 'salaryMin',
    label: 'Salary',
    canSort: true,
    customClasses: 'text-center',
    customDivStyles: { paddingLeft: '5px' },
  },
  {
    key: 'location.city',
    label: 'Location',
    canSort: false,
    customClasses: 'text-left location-paddingX-0',
    customDivStyles: {
      display: 'flex',
      justifyContent: 'flex-start'
    },
  },
  /*
  {
    key: 'status',
    label: 'Status',
    canSort: false,
    customClasses: 'text-center',
    customDivStyles: {},
  },
  {
    key: 'chat',
    label: 'Chat',
    canSort: false,
    customClasses: 'text-center',
    customDivStyles: {},
  },
  {
    key: 'action',
    label: 'Select/DeSelect',
    canSort: false,
    customClasses: 'text-center',
    customDivStyles: {},
  },
  */
  {
    key: 'invitation',
    label: 'Invitation',
    canSort: false,
    customClasses: 'text-center',
    subClasses: '',
    customDivStyles: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingRight: '45px'
    },
  },
  {
    key: 'created_at',
    label: 'Created At',
    canSort: true,
    customClasses: 'text-center',
    customDivStyles: {
      paddingLeft: '15px',
      width: '7rem'
    },
  },
];

export const agency = [
  {
    key: 'action',
    label: '',
    selectAll: true,
    canSort: false,
    customClasses: 'text-center',
    customDivStyles: { paddingRight: '0px', paddingLeft: '7px' },
  },
  {
    key: 'icon',
    label: '',
    customClasses: 'pl-4',
    customDivStyles: {},
  },
  {
    key: 'name',
    label: 'Candidate Details',
    canSort: true,
    customClasses: 'text-left',
    customDivStyles: { display: 'flex', justifyContent: 'flex-start', marginLeft: '-0.9rem' },
  },
  {
    key: 'experience',
    label: 'Experience',
    canSort: true,
    customClasses: 'text-center pl-0',
    customDivStyles: {},
  },
  {
    key: 'salaryMin',
    label: 'Salary',
    canSort: true,
    customClasses: 'text-center',
    customDivStyles: { paddingLeft: '5px' },
  },
  {
    key: 'location.city',
    label: 'Location',
    canSort: false,
    customClasses: 'text-left location-paddingX-0',
    customDivStyles: {
      display: 'flex',
      justifyContent: 'flex-start'
    },
  },
  {
    key: 'created_at',
    label: 'Created At',
    canSort: true,
    customClasses: 'text-center',
    customDivStyles: {
      paddingLeft: '15px'
    },
  },
];