import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { EXPRESS_SERVER_URL } from '../../../config'
import moment from 'moment'
import CherryPagination from '../../../components/common/Pagination/Pagination'
import { DEFAULT_PER_PAGE } from '../../../utils/constants'
import { useQuery, useSubscription } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { TabTitle } from '../../../components/common'
const ModalContext = React.createContext();
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import DownloadIcon from './DownloadIcon'

const COMPANY_PROFILE = gql`
  query getCompanyProfile($admin_id: String) {
    company(where: { adminID: { _eq: $admin_id } }) {
      id
      name
      logoURL
      email
      adminName
      websiteURL
      size
      bio
      totalCredit
      remainingCredit
      subscription_type
    }
  }
`;

const ALL_FILTERED_AGENCIES = gql`
  subscription getFiltered_Agencies($search_term: String, $perPage: Int, $offset: Int, $order_by: order_by) {
    company(
      where: { is_agency: { _eq: true },  _or: [{ name: { _is_null: false } }, { name: { _ilike: $search_term } }, { email: { _ilike: $search_term } }]},
      limit: $perPage,
      offset: $offset,
      order_by: { name: $order_by },
      ) {
        id
        name
    		email
        websiteURL
        size
        logoURL
    		is_disabled
    }
  }
`;

const ALL_AGENCIES_TOTAL = gql`
subscription getFiltered_Companies($search_term: String, $offset: Int) {
  company_aggregate(
    where: { is_agency: { _eq: true },  _or: [{ name: { _is_null: false } }, { name: { _ilike: $search_term } }, { email: { _ilike: $search_term } }]},
    offset: $offset,
    ) {
      aggregate {
        count
      }
  }
}
`;

const FEE_PAYMENT_LIST = gql`
subscription getFeePaymentList($subscriber_id: uuid, $start_date: timestamptz, $end_date: timestamptz, $perPage: Int, $offset: Int, $order_by: order_by, $status: String) {
    fee_payment(
        where: {subscriber_id: {_eq: $subscriber_id}, invoice_generation_on: { _gte: $start_date, _lte: $end_date }, status: { _eq: $status } },
        limit: $perPage,
        order_by: { invoice_generation_on: desc },
        offset: $offset,
        ) {
      invoice_id
      invoice_generation_on
      invoice_amount
      invoice_paid_on
      invoice_due_on
      status
      invoice_payment_link
      invoice_sent_on
      invoice_url
      subscriber_id
      id
      candidate_id
      candidate {
        name
      }
      agency_id
    }
  }
`;

const FEE_PAYMENT_LIST_TOTAL = gql`
 subscription getFiltered_Companies($subscriber_id: uuid, $offset: Int, $start_date: timestamptz, $end_date: timestamptz, $status: String) {
  fee_payment_aggregate(
        where: {subscriber_id: {_eq: $subscriber_id}, invoice_generation_on: { _gte: $start_date, _lte: $end_date }, status: { _eq: $status } },
    ) {
      aggregate {
        count
      }
  }
}
`;


function TransactionDashboard({ adminID, stripeID }) {
    const [feePaymentListData, setFeePaymentListData] = useState([]);
    const [feePaymentListStatus, setFeePaymentListStatus] = useState(null);
    const [sortType, setSortType] = useState();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(new Date());
    const [currentUser, setCurrentUser] = useState({})
    const { data } = useQuery(
        COMPANY_PROFILE,
        { variables: { admin_id: adminID } },
    );
    useEffect(() => {
        if (data) {
            setCurrentUser({
                id: data.company[0].id,
            });
        }

    }, [data]);

    // pagination
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE);
    const [total, setTotal] = useState(10);

    function handlePaginationChange(event) {
        setPage(event.page);
        setPerPage(event.perPage);
    }

    const { data: feePaymentList, loading } = useSubscription(
        FEE_PAYMENT_LIST,
        // flag ?
        {
            variables: {
                subscriber_id: currentUser?.id,
                start_date: startDate != null ? moment(startDate).startOf('day').utc().toISOString() : null,
                end_date: moment(endDate).endOf("day").utc().toISOString(),
                perPage: perPage ? perPage : null,
                offset: page * perPage ? page * perPage : 0,
                order_by: sortType?.direction,
                status: feePaymentListStatus != null ? feePaymentListStatus : null
            },
        },
    );

    const { data: totalFeePaymentList } = useSubscription(
        FEE_PAYMENT_LIST_TOTAL,
        // flag ?
        {
            variables: {
                subscriber_id: currentUser?.id,
                start_date: startDate != null ? moment(startDate).startOf("day").utc().toISOString() : null,
                end_date: moment(endDate).endOf("day").utc().toISOString(),
                status: feePaymentListStatus != null ? feePaymentListStatus : null
            },
        },
    );

    useEffect(() => {
        if (feePaymentList && feePaymentList.fee_payment) {
            setFeePaymentListData(feePaymentList.fee_payment);
        }
    }, [feePaymentList]);

    useEffect(() => {
        if (totalFeePaymentList && totalFeePaymentList.fee_payment_aggregate) {
            setTotal(totalFeePaymentList?.fee_payment_aggregate?.aggregate?.count)
        }
    }, [totalFeePaymentList]);

    let activityData = feePaymentListData || [];

    const handleDownloadInvoiceClick = (url) => {
        window.open(url, 'noopener,noreferrer');
    }

    const handlePaymentClick = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    const handleOnTransactionFilterChange = (e) => {
        let val = e.target.value;
        val = val === "all" ? null : val;
        setFeePaymentListStatus(val);
    }

    return (
        <div className="w-full flex-1 candidates">

            <div className="h-full flex flex-col mt-4" style={{ width: '100%', height: 60, display: '-webkit-inline-box' }}>
                <div style={{ marginRight: 20 }}>
                    <span>Start Date: </span>
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} maxDate={moment(new Date()).toDate()} />
                </div>
                <div>
                    <span>End Date: </span>
                    <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} minDate={startDate} maxDate={moment(new Date()).toDate()} />
                </div>
                <select
                    className="px-md ml-4 rounded relative cursor-pointer z-20"
                    style={{
                        WebkitAppearance: 'none',
                        width: 231,
                        height: 41,
                        fontWeight: 500,
                        fontSize: 17,
                    }}
                    onChange={handleOnTransactionFilterChange}
                >
                    <option value="all">All Payments</option>
                    <option value="invoice_generated">Pending Payments</option>
                    <option value="paid">Completed Payments</option>
                    <option value="expired">Expired Payments</option>
                    <option value="failed">Failed Payments</option>
                </select>
            </div>
            <div className={`flex-1 bg-white rounded shadow candidates-container candidate-blank-dash`} style={{ overflowX: 'auto' }}>
                <table style={{ width: '100%' }}>
                    <thead style={{ fontSize: 14, fontWeight: 'bold' }}>
                        <tr style={{ position: 'sticky', top: 0, background: 'white' }}>
                            <th style={{ textAlign: 'left', paddingLeft: '15px' }}>Candidate</th>
                            <th style={{ textAlign: '' }}>Invoice Date</th>
                            {/* <th style={{ paddingLeft: '35px', textAlign: 'center' }}>Status</th> */}
                            <th style={{ paddingRight: '0px', textAlign: 'center' }}>Due Date</th>
                            <th style={{ paddingLeft: '35px', textAlign: 'center' }}>Payment Status</th>
                            <th style={{ textAlign: '' }}>Download</th>
                            {/* <th style={{ textAlign: 'center' }}>Guaranteed Date</th>  */}
                        </tr>
                    </thead>

                    <tbody>
                        {(activityData || []).map((activity, i) => {
                            return (
                                <tr key={`activity${i}`}>
                                    <td>
                                        <div className="font-medium text-darkgray flex items-start" style={{ fontSize: 14, justifyContent: 'start', paddingLeft: '15px' }}>
                                            {activity.candidate ? activity?.candidate?.name : 'N/A'}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="font-medium text-darkgray flex items-start" style={{ fontSize: 14, justifyContent: 'start' }}>
                                            {activity.invoice_generation_on ? moment(activity?.invoice_generation_on).format('MM/DD/YYYY') : 'N/A'}
                                        </div>
                                    </td>
                                    {/* <td style={{ paddingLeft: '35px' }}>
                                        <div className="font-medium flex items-center" style={{ fontSize: 14, justifyContent: 'center' }}>
                                            {activity?.invoice_paid_on ? 'Paid' : 'Not paid'}
                                        </div>
                                    </td>
                                    */}
                                    <td style={{ paddingLeft: '35px' }}>
                                        <div className="font-medium flex items-center" style={{ fontSize: 14, justifyContent: 'center' }}>
                                            {activity?.invoice_due_on ? moment(activity.invoice_due_on).format('MM/DD/YYYY') : 'N/A'}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="font-medium text-darkgray flex items-center" style={{ fontSize: 14, justifyContent: 'center' }}>
                                            {activity?.invoice_paid_on ? <span className='text-center text-green'>paid <br /> {moment(activity.invoice_paid_on).format('MM/DD/YYYY')}</span> : <button onClick={() => handlePaymentClick(activity?.invoice_payment_link)} className='mark-dander-btn'>Pay Now</button>}
                                        </div>
                                    </td>
                                    <td>
                                        <div title='Download Invoice' onClick={() => handleDownloadInvoiceClick(activity?.invoice_url)} className="font-medium text-darkgray flex items-center" style={{ fontSize: 14, paddingLeft: '20px', cursor: 'pointer' }}>
                                            <DownloadIcon />
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}

                    </tbody>
                </table>
                {loading && (
                    <div className={`flex justify-center items-center flex-1 candidate-blank-dash`} style={{ width: '100%' }}>
                        <div className="bg-lightgray text-darkgray py-sm px-md" style={{ borderRadius: 43 }}>
                            Loading...
                        </div>
                    </div>
                )}
                {!activityData?.length ? <div className={`flex justify-center items-center flex-1 candidate-blank-dash`}>
                    <div className="bg-lightgray text-darkgray py-sm px-md" style={{ borderRadius: 43 }}>
                        No Data Found
                    </div>
                </div> : null}
            </div>
            <div className="candidate-pagination">
                <CherryPagination page={page} perPage={perPage} total={total} onChange={handlePaginationChange} />
            </div>
        </div>
    )
}

export default TransactionDashboard