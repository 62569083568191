import 'firebase/auth';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import Loader from 'react-loader-spinner';
import { Slider } from '@material-ui/core';
import * as Yup from 'yup';
import './fee-config-form.css';
import { useEffect } from 'react';

export default function FeeConfigForm({ initialValues: initialFieldsVal, saveProfile, navigate, loading, errors }) {
  const [initialValues, setInitialValues] = useState(initialFieldsVal);
  const [sharePercentages, setSharePercentages] = useState({
    cherrypickerShare: initialValues.cherrypicker_share,
    recruiterShare: initialValues.recruiter_share,
  });

  useEffect(() => {
    setSharePercentages(prevState => {
      return {
        ...prevState, 
        cherrypickerShare: initialFieldsVal.cherrypicker_share,
        recruiterShare: initialFieldsVal.recruiter_share,
      }
    })
    setInitialValues(initialFieldsVal)
  }, [initialFieldsVal])

  const { cherrypickerShare, recruiterShare } = sharePercentages;
  const feePercentageRequireMessage = 'Placement fee is required';
  const guaranteePeriodRequireMessage = 'Guarantee Period is required';
  const cherrypickerShareRequireMessage = 'Cherrypicker Share is required';
  const recruiterShareRequireMessage = 'Recruiter Share is required';

  const FeeConfigSchema = Yup.object().shape({
    placement_fee_percentage: Yup.number().required(feePercentageRequireMessage),
    guarantee_period: Yup.number().required(guaranteePeriodRequireMessage),
    cherrypicker_share: Yup.number().required(cherrypickerShareRequireMessage),
    recruiter_share: Yup.number().required(recruiterShareRequireMessage),
  });

  const labels = {
    placement_fee_percentage: 'Placement Fee:',
    guarantee_period: 'Guarantee Period (in Days):',
    cherrypicker_share: 'Cherrypicker Share:',
    recruiter_share: 'Recruiter Share:',
  };

  const sliders = [
    { field: 'cherrypickerShare', otherField: 'recruiterShare', value: sharePercentages.cherrypickerShare, label: 'Cherrypicker share' },
    { field: 'recruiterShare', otherField: 'cherrypickerShare', value: sharePercentages.recruiterShare, label: 'Recruiter share' },
  ];

  const disabled = false;

  const handleOnPercentageChange = name => e => {
    let value = e.target?.value
    value = value > 100 ? 100 : value;
    setSharePercentages(prevState => {
      return { ...prevState, [name]: value, recruiterShare: 100 - Number(value) }
    })
  }

  return (
    <div className="flex flex-col flex-1 w-full">
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={(values, actions) => {
          saveProfile({ ...values, cherrypicker_share: cherrypickerShare, recruiter_share: recruiterShare });
        }}
        validationSchema={FeeConfigSchema}
      >
        {({ setFieldValue }) => (
          <Form className="flex flex-col h-full w-full">
            {Object.entries(initialValues).map(([label, value], index) => {
              switch (label) {
                case 'placement_fee_percentage':
                  return (
                    <div key={index}>
                      {labels[label]}
                      <Field name="placement_fee_percentage">
                        {({ field, form, meta }) => {
                          return (
                            <>
                              <input
                                className="text-sm  px-md rounded w-full border border-darkgray"
                                type='number'
                                style={{ height: 55, marginTop: 16, marginBottom: 16, borderColor: '#c4cad3' }}
                                {...field}
                              />
                              {errors && errors.fee_percentage && <div className="text-xs text-red font-medium mt-sm text-center">{errors.fee_percentage}</div>}
                              {form.errors['placement_fee_percentage'] && form.touched['placement_fee_percentage'] && (
                                <div className="text-xs text-red font-medium mt-sm text-center">{form.errors['placement_fee_percentage']}</div>
                              )}
                            </>
                          );
                        }}
                      </Field>
                    </div>
                  );
                case 'guarantee_period':
                  return (
                    <div key={index}>
                      {labels[label]}
                      <Field name="guarantee_period">
                        {({ field, form, meta }) => {
                          return (
                            <>
                              <input
                                className="text-sm  px-md rounded w-full border border-darkgray"
                                type='number'
                                style={{ height: 55, marginTop: 10, marginBottom: 10, borderColor: '#c4cad3' }}
                                {...field}
                              />
                              {form.errors['guarantee_period'] && form.touched['guarantee_period'] && (
                                <div className="text-xs text-red font-medium mt-sm text-center">{form.errors['guarantee_period']}</div>
                              )}
                            </>
                          );
                        }}
                      </Field>
                    </div>
                  );
                default:
              }
            })}
            {/* {sliders.map((slider, index) => (
              <div className={`mt-md ${index !== sliders.length - 1 ? 'mb-md' : ''}`} key={slider.field}>
                <div className="mb-2">{slider.label}</div>
                <Slider
                  value={slider.value}
                  valueLabelFormat={(value) => `${value}`}
                  onChange={(e, value) => {
                    setSharePercentages((prevPercentages) => {
                      return { ...prevPercentages, [slider.field]: value, [slider.otherField]: 100 - value };
                    });
                  }}
                  max={100}
                  min={0}
                  color={'blue'}
                  valueLabelDisplay="on"
                />
              </div>
            ))} */}
            <div className='flex flex-row justify-between'>
              <div className='flex flex-col justify-start align-middle w-2/5'>
                <div className="mt-2">{labels.cherrypicker_share}</div>
                <input
                  className='share-input'
                  type='text'
                  onChange={handleOnPercentageChange("cherrypickerShare")}
                  value={cherrypickerShare}
                  min={0.1}
                  max={100.00}
                />
              </div>
              <div className='flex flex-col justify-start align-middle w-2/5'>
                <div className="mt-2">{labels.recruiter_share}</div>
                <input
                  className='share-input'
                  type='number'
                  onChange={handleOnPercentageChange("recruiterShare")}
                  value={recruiterShare}
                  disabled={true}
                  min={0.1}
                  max={100.00}
                />
              </div>
            </div>
            <div className="flex w-full" style={{ marginTop: 67 }}>
              <button
                onClick={() => navigate('Profile')}
                type="button"
                style={{ height: 55 }}
                className="bg-lightgray flex items-center justify-center shadow text-xs font-medium flex-1 text-darkgray rounded mr-sm p-sm"
              >
                Cancel
              </button>
              {/* upload button will be enabled once the API is ready */}
              <button
                type="submit"
                // disabled={true}
                style={{ cursor: disabled ? 'default' : 'pointer', height: 55 }}
                className={`${disabled ? 'bg-lightred' : 'bg-red shadow-xl'} flex items-center justify-center  shadow ml-sm text-xs font-medium flex-1 text-white rounded p-sm`}
              >
                {loading ? <Loader type="TailSpin" color="#FFFFFF" height={20} width={20} /> : 'Update'}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
