import React from 'react';
import CandidateDashboard from '../candidate-dashboard';

const FindCandidates = ({ companyDetails = {} }) => {
    const companyId = companyDetails?.id || ''
    return (
        <CandidateDashboard
            BASE_FILTER={`where: {_not: {company_candidates: {company_id: {_eq: "${companyId}"}, isBlock: {_eq: true}}}}`}
            filterBySearch={true} 
            context="find-candidates" 
            searchPlaceholder="Job title" 
            includeSalaryMetrics={true} 
        />
    )
}

export default FindCandidates;
