import { useMutation, useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase from 'firebase/app';
import moment from 'moment';
import fetch from 'node-fetch';
import React, { useContext, useEffect, useState } from 'react';
import { EXPRESS_SERVER_URL, WEBSITE_URL } from '../../config';
import { ApolloContext } from '../../context/Apollo';
import ApprovalConfirmModal from '../../views/jobs/approval-confirm-modal';
import ZeroCreditModal from './ZeroCreditModal';
import { useSnackbar } from 'react-simple-snackbar';
import { EMAIL_TEMPLATES } from '../../utils/constants';
import axios from 'axios';
import ConfirmationModal from './ConfirmationModal';

const COMPANY_REQUEST = gql`
  mutation createCompanyRequest($candidate_id: String, $company_id: uuid, $status: String, $isDirectRequest: Boolean) {
    insert_company_request(objects: { candidate_id: $candidate_id, company_id: $company_id, status: $status, isDirectRequest: $isDirectRequest }) {
      affected_rows
      returning {
        candidate_id
        company_id
        candidate {
          name
          email
          recruiter {
            email
            name
            company {
              name
            }
          }
        }
      }
    }
  }
`;

const COMPANY_REQUEST_HISTORY = gql`
  mutation createCompanyRequestHistory($obj: [company_request_history_insert_input!]!) {
    insert_company_request_history(objects: $obj) {
      affected_rows
    }
  }
`;

const GET_COMPANY_ID = gql`
  query getCompanyID($admin_id: String) {
    company(where: { adminID: { _eq: $admin_id } }) {
      id
      name
      totalCredit
      remainingCredit
      subscription_type
    }
  }
`;

const GET_SETTINGS = gql`
  query getSettings {
    settings {
      credit_rate
      daily_candidate_request_limit
      monthly_candidate_request_limit
      weekly_candidate_request_limit
      id
    }
  }
`;

const GET_REQUESTS_USED = gql`
  query getDailyRequestsUsed($companyId: uuid, $startDate: String, $endDate: String) {
    company_request_history_aggregate(where: { company_id: { _eq: $companyId }, created_at: { _gte: $startDate, _lte: $endDate } }) {
      aggregate {
        count
      }
    }
  }
`;

const GET_CANDIDATE = gql`
  query getCandidateDetails($id: String) {
    candidate(where: { id: { _eq: $id } }) {
      id
      data_source
      tp_id
      is_enriched
      name
      email
      is_login
      device_token
      invite_code
      recruiter {
        id
        email
        name
      }
    }
  }
`;

const UPDATE_CREDIT = gql`
  mutation updateCompany($id: uuid, $totalCredit: numeric, $remainingCredit: numeric) {
    update_company(where: { id: { _eq: $id } }, _set: { totalCredit: $totalCredit, remainingCredit: $remainingCredit }) {
      affected_rows
      returning {
        id
        name
        totalCredit
        remainingCredit
        subscription_type
      }
    }
  }
`;

const CREATE_PAYMENT_HISTORY = gql`
  mutation CreatePayment($credits: numeric, $date: String, $type: smallint, $company_id: uuid, $candidate_id: String, $credit_amount: numeric) {
    insert_payment_history(objects: { credits: $credits, date: $date, type: $type, company_id: $company_id, candidate_id: $candidate_id, credit_amount: $credit_amount }) {
      returning {
        id
        credits
        date
        type
        company_id
        company {
          email
        }
      }
    }
  }
`;

const RequestInterviewButton = ({ candidateID, size, bg, status: originalStatus, companyID, remainingCredit, candidate, handleOnCandidateStatusChange, actionModal, handleCandidateStatusChange, handleCandidateCancelStatus, hide }) => {
  const [status, setStatus] = useState(`${!originalStatus ? 'request' : ['hired', 'approved'].includes(originalStatus) ? 'approved' : 'requested'}`);
  const [createCandidateRequest, { data: sendRequestResponse }] = useMutation(COMPANY_REQUEST);
  const [createCandidateRequestHistory] = useMutation(COMPANY_REQUEST_HISTORY);

  const [subscriber, setSubscriber] = useState();
  const [subscriptionType, setSubscriptionType] = useState();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [modal, setModal] = useState({ data: null, open: false });
  const [zeroModal, setZeroModal] = useState({ data: null, open: false });
  const { apolloClient } = useContext(ApolloContext);
  const [openSnackbar] = useSnackbar();
  const [updateCompanyCredit, { data: submissionResponse, loading }] = useMutation(UPDATE_CREDIT);

  const { data: companyResponse } = useQuery(GET_COMPANY_ID, {
    variables: {
      admin_id: firebase.auth().currentUser && firebase.auth().currentUser.uid,
    },
  });

  const [createPaymentHistory, { data: paymentResponseData }] = useMutation(CREATE_PAYMENT_HISTORY);

  useEffect(() => {
    if (companyResponse) {
      setSubscriber(companyResponse.company && companyResponse.company[0]);
      setSubscriptionType(companyResponse.company && companyResponse.company[0] && companyResponse.company[0].subscription_type);
    }
  }, [companyResponse]);

  const options = {
    request: { bg: '#FFFFFF', color: 'rgb(86, 208, 0)', label: 'Request an Introduction' },
    requested: { bg: '#fed8b1', color: '#FF8C00', label: 'Introduction Requested' },
    accepted: { bg: 'white', color: 'rgb(86, 208, 0)', label: 'Unlock Candidate' },
    pending: { bg: '#FFFFFF', color: '#FF8C00', label: 'Introduction Requested' },
    approved: { bg: '#6ACA63', color: 'white', label: 'Profile Unlocked' },
    loading: { bg: bg || 'white', color: 'rgb(86, 208, 0)', label: 'Loading...' },
    declined: { bg: '#FFD2D2', color: '#D8000C', label: 'Request Declined' },
    hired: { bg: '#6ACA63', color: 'white', label: 'Profile Unlocked' },
  };

  const padding = size === 'small' ? 2 : 5;
  const minWidth = size === 'small' ? '100px' : '200px';
  const width = size === 'small' ? '70%' : '100%';

  const sendRequest = async () => {
    const { remainingCredit, totalCredit, id, name: subscriberName, email: subscriberEmail } = subscriber || {};

    if (status == 'accepted') {
      if (remainingCredit > 0 || subscriptionType == 'PAID') {
        setModal({
          open: true,
          data: {
            subscriber,
            candidateID,
          },
          isFresh: true,
        });
      } else {
        setZeroModal({
          open: true,
        });
      }
    }
    else {
      if (remainingCredit <= 0) {
        setZeroModal({
          open: true,
        });

        return;
      }
      updateCompanyCredit({
        variables: {
          id,
          remainingCredit: parseInt(remainingCredit) - 1,
          totalCredit: parseInt(totalCredit) - 1,
        },
      });

      const dateString = moment(new Date()).format('YYYY-MM-DD');
      const paymentHistory = {
        credits: 1,
        date: dateString,
        type: 2,
        company_id: companyID,
        candidate_id: candidateID,
        credit_amount: 1,
      };
      createPaymentHistory({
        variables: paymentHistory,
      });
      if (((parseInt(remainingCredit) - 1) <= 1) && subscriptionType === 'FREE' ) {
        fetch(`${EXPRESS_SERVER_URL}/send-email`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
              recipient: 'admin@getcherrypicker.com',
              template_id: EMAIL_TEMPLATES.TO_ADMIN_WHEN_SUBSCRIBER_FREE_CREDIT_ZERO,
              attributes: {
                subscriber_name: subscriberName,
                subscriber_email: subscriberEmail
              },
          }),
        });
      }
      /*
      // Check if can send request
      // C // if (response.data.company[0].remainingCredit <= 0) {
      setModal({ ...modal, open: false, reset: true });

      return;
    }heck only if account is free. If it is paid, then requests are always allowed
      if (subscriber.subscription_type === 'FREE') {
        const response = await apolloClient.query({
          query: GET_SETTINGS,
          variables: {},
          fetchPolicy: 'network-only',
        });

        const settings = response.data.settings[0];

        const dailyRequestSentResponse = await apolloClient.query({
          query: GET_REQUESTS_USED,
          variables: {
            companyId: companyID,
            startDate: moment()
              .startOf('day')
              .format('YYYY-MM-DD'),
            endDate: moment()
              .endOf('day')
              .format('YYYY-MM-DD'),
          },
          fetchPolicy: 'network-only',
        });

        const dailyRequestSent = dailyRequestSentResponse.data.company_request_history_aggregate.aggregate.count;

        if (dailyRequestSent >= settings.daily_candidate_request_limit) {
          openSnackbar('Cannot send request. You have reached your daily limit.');
          return;
        }

        const weeklyRequestSentResponse = await apolloClient.query({
          query: GET_REQUESTS_USED,
          variables: {
            companyId: companyID,
            startDate: moment()
              .startOf('week')
              .format('YYYY-MM-DD'),
            endDate: moment()
              .endOf('week')
              .format('YYYY-MM-DD'),
          },
          fetchPolicy: 'network-only',
        });

        const weeklyRequestSent = weeklyRequestSentResponse.data.company_request_history_aggregate.aggregate.count;

        if (weeklyRequestSent >= settings.weekly_candidate_request_limit) {
          openSnackbar('Cannot send request. You have reached your weekly limit.');
          return;
        }

        const monthlyRequestSentResponse = await apolloClient.query({
          query: GET_REQUESTS_USED,
          variables: {
            companyId: companyID,
            startDate: moment()
              .startOf('month')
              .format('YYYY-MM-DD'),
            endDate: moment()
              .endOf('month')
              .format('YYYY-MM-DD'),
          },
          fetchPolicy: 'network-only',
        });

        const monthlyRequestSent = monthlyRequestSentResponse.data.company_request_history_aggregate.aggregate.count;

        if (monthlyRequestSent >= settings.monthly_candidate_request_limit) {
          openSnackbar('Cannot send request. You have reached your monthly limit.');
          return;
        }
      }
      if (status === "request") {
        const {
          id = '',
          invite_code = '',
          recruiter: { name: recruiterName = '' } = {},
        } = candidate || {};
        try {
          const { data } = await apolloClient.query({
            query: GET_CANDIDATE,
            variables: { id },
            fetchPolicy: 'network-only',
          });
          const candidateName = data?.candidate?.[0]?.name;
          const candidateEmail = data?.candidate?.[0]?.email;
          if (candidate.is_login === '1' && candidate.device_token) {
            console.log('candidate having device token');
            //  await axios.post(`${EXPRESS_SERVER_URL}/candidate-invitation-with-promo`, {
            //   candidateId: id, 
            //   recruiterName: recruiterName,
            //  });
          } else {
            // add-candidate-to-campaign
            fetch(`${EXPRESS_SERVER_URL}/add-candidate-to-campaign`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                email: candidateEmail,
                name: candidateName,
                inviteCode: invite_code
              }),
            });
          }
        } catch (error) {
          console.log({error})          
        }
      }
      */
      try {
        const { data } = await apolloClient.query({
          query: GET_CANDIDATE,
          variables: { id: candidate?.id },
          fetchPolicy: 'network-only',
        });
        const { id, name: candidateName, invite_code, email: candidateEmail, is_login, device_token, is_enriched, data_source, tp_id, recruiter: { name: recruiterName } } = data?.candidate?.[0] || {};
        if(!is_enriched && data_source === 'coresignal') {
          const {data: { status, linkedinUrl } } = await axios.get(`${EXPRESS_SERVER_URL}/core-signal/${tp_id}/${candidate?.id}`)
          if(status !== 'success'){
             return;
          }
          if(linkedinUrl) {
            await axios.post(`${EXPRESS_SERVER_URL}/api-clay`, { linkedinUrl, candidateID: candidate?.id });
          }
        } else {
          if (is_login === '1' && device_token) {
            console.log('candidate having device token');
            //  await axios.post(`${EXPRESS_SERVER_URL}/candidate-invitation-with-promo`, {
            //   candidateId: id, 
            //   recruiterName: recruiterName,
            //  });
          } else {
            // add-candidate-to-campaign
            fetch(`${EXPRESS_SERVER_URL}/add-candidate-to-campaign`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                email: candidateEmail,
                name: candidateName,
                inviteCode: invite_code
              }),
            });
          }
        }
      } catch (error) {
        console.log({ error });
        return;
      }
      createCandidateRequest({
        variables: {
          candidate_id: candidateID,
          company_id: companyID,
          status: 'requested',
          isDirectRequest: true,
        },
      });

      createCandidateRequestHistory({
        variables: { obj: { candidate_id: candidateID, company_id: companyID, created_at: moment().format('YYYY-MM-DD'), status: 'requested' } },
      });

      setStatus('requested');
      actionModal && handleOnCandidateStatusChange('requested')
      !actionModal && hide && handleCandidateStatusChange(candidate, 'requested');
    }
  };

  useEffect(() => {
    if (sendRequestResponse) {
      let email = '';
      let candidate_name = '';
      let recruiter_name = '';
      if (
        sendRequestResponse.insert_company_request.returning[0] &&
        sendRequestResponse.insert_company_request.returning[0].candidate &&
        sendRequestResponse.insert_company_request.returning[0].candidate.recruiter &&
        sendRequestResponse.insert_company_request.returning[0].candidate.recruiter.email
      ) {
        email = sendRequestResponse.insert_company_request.returning[0].candidate.recruiter.email;
        candidate_name = sendRequestResponse.insert_company_request.returning[0].candidate.name;
        recruiter_name = sendRequestResponse.insert_company_request.returning[0].candidate.recruiter.name;
      } else {
        email = 'cjtufano@getcherrypicker.com';
      }
      let company_name = 'NA';
      if (companyResponse?.company && Array.isArray(companyResponse?.company) && companyResponse?.company?.length && companyResponse?.company[0]?.name) {
        company_name = companyResponse?.company[0]?.name;
      }
      fetch(`${EXPRESS_SERVER_URL}/send-email`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          recipient: email,
          // subject: 'Subscriber interested in your candidate profile', // added to sendGrid
          template_id: EMAIL_TEMPLATES.TO_RECRUITER_WHEN_SUBSCRIBER_INTERESTED_IN_CANDIDATE_PROFILE, // verified
          attributes: {
            recruiter_name,
            candidate_name,
            company_name,
            website_url: WEBSITE_URL,
          },
        }),
      });
    }
  }, [sendRequestResponse]);

  const disabled = status === 'requested' || status === 'approved' || status === 'declined' || status === 'hired';

  useEffect(() => {
    setStatus(
      !originalStatus
        ? 'request'
        : originalStatus === 'approved'
          ? 'approved'
          : originalStatus === 'declined'
            ? 'declined'
            : originalStatus === 'pending' || originalStatus === 'requested'
              ? 'requested'
              : originalStatus === 'accepted'
                ? 'accepted'
                : originalStatus === 'hired'
                  ? 'hired'
                  : 'request',
    );
  }, [originalStatus]);
  const handleRequestInterviewBtnClick = () => {
    sendRequest();
  }
  const isFreeAccount = subscriptionType === 'FREE' ? true : false;
  return (
    <>
      <button
        disabled={disabled}
        onClick={() => setOpenConfirmationModal(true)}
        style={{
          cursor: disabled ? 'default' : 'pointer',
          color: options[status].color,
          borderColor: 'rgb(86, 208, 0)',
          backgroundColor: options[status].bg,
          border: '1px solid',
          borderRadius: 5,
          height: 40,
          width,
          minWidth,
          marginBottom: 2,
          paddingLeft: padding,
          paddingRight: padding,
        }}
        className={`text-xs flex justify-center items-center rounded`}
      >
        {`${options[status].label}`}
      </button>
      <ApprovalConfirmModal state={[modal, setModal]} />
      <ConfirmationModal modalTitle="Request an Introduction" open={openConfirmationModal} showCancel={true} confirm={handleRequestInterviewBtnClick} onChange={(e) => setOpenConfirmationModal(e)}>
          <p>You're about to send an introduction request to the selected candidate, which will use 1 credit. Would you like to proceed?</p>
      </ConfirmationModal>
      <ZeroCreditModal state={[zeroModal, setZeroModal]} modalTitle="Out of Credits!" modalBody={isFreeAccount ? "Please contact support@getcherrypicker.com to get more introduction credits." : "To continue connecting with top talent, you’ll need to purchase more credits."} isFreeAccount={isFreeAccount} subscriptionType={subscriptionType}/>
    </>
  );
};

export default RequestInterviewButton;
